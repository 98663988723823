<template>
    <div class="content">
        <template v-if="loaded">
            <div class="header">
                <div class="title">{{ objective.name }}</div>
                <slot name="header_menu_right"></slot>
            </div>

            <div class="sub-header-info">
                <div class="data">
                    <div class="label">{{ $t('general.responsible')}}</div>
                    <div class="data-info">
                        <span class="text">{{ objective.responsible.name }}</span>
                    </div>
                </div>
                <div class="data">
                    <div class="label">{{ $t('general.progress')}}</div>
                    <div class="data-info">
                        <span class="text">{{ objective.percent}}%</span>
                    </div>
                </div>
                <div class="data">
                    <div class="label">{{ $t('meeting_session.deadline')}}</div>
                    <div class="data-info">
                        <span class="text">{{ moment(objective.deadline).format('DD MMMM YYYY')}}</span>
                    </div>
                </div>
                <div class="data">
                    <div class="label">{{ $t('meeting_session.topic.key_results') }}</div>
                    <div class="data-info">
                        <span class="text">{{ objective.key_results.length}}</span>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="container-form">
                    <div class="table-list">
                        <template v-for="keyResult in objective.key_results">
                            <div class="row-item header-category">
                                <!-- name -->
                                <div class="column-name">
                                    <div class="text">
                                        {{ keyResult.name }}
                                    </div>
                                    <v-popover offset="5" trigger="click" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                                        <icon-info/>

                                        <template slot="popover">
                                            <div class="title">{{ keyResult.name }}</div>
                                            <div class="info-row">
                                                <div class="label">{{ $t('general.responsible')}}:  </div>
                                                <div class="simple-text">{{ keyResult.responsible.name }}</div>
                                            </div>

                                            <div class="info-row" v-if="keyResult.description">
                                                <div class="label">{{ $t('general.description')}}:  </div>
                                                <div class="simple-text">{{ keyResult.description }}</div>
                                            </div>

                                            <div class="info-row">
                                                <div class="label">{{ $t('responsibility.progress_type_title') }}:  </div>
                                                <div class="simple-text">{{ $t('show_user.key_result_progress_type.' + keyResult.progress_type, { to: keyResult.target % 1 != 0 ? $options.filters.numeral(keyResult.target ,  '0,0.00') : $options.filters.numeral(keyResult.target ,  '0,0'), unit: keyResult.unity, from: keyResult.start_value % 1 != 0 ? $options.filters.numeral(keyResult.start_value ,  '0,0.00') : $options.filters.numeral(keyResult.start_value ,  '0,0')}) }}</div>
                                            </div>

                                            <div class="info-row">
                                                <div class="label">{{ $t('meeting_session.frequency_update') }}:  </div>
                                                <div class="simple-text">{{ $t('meeting_session.update_frequencies', {n: keyResult.measurement }) }}</div>
                                            </div>
                                            
                                            <div class="info-row">
                                                <div class="label">{{ $t('meeting_session.running_period') }}:</div>
                                                <div class="simple-text">{{ moment(keyResult.start_date).format('DD MMM YYYY') }} - {{ moment(keyResult.end_date).format('DD MMM YYYY') }}</div>
                                            </div>
                                        </template>
                                    </v-popover>
                                </div>

                                <!-- last value -->
                                <div class="column-info" v-if="keyResult.last_key_result_log">
                                    <span class="label">{{ $t('show_user.last_value') }}:</span>
                                    <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                                        <span class="value">{{ returnValue(keyResult.last_key_result_log.value) }} {{ keyResult.unity }}</span>
                                        
                                        <template slot="popover">
                                            <div class="simple-text">{{ $t('show_user.key_result_progress_type.' + keyResult.progress_type, { to: keyResult.target % 1 != 0 ? $options.filters.numeral(keyResult.target ,  '0,0.00') : $options.filters.numeral(keyResult.target ,  '0,0'), unit: keyResult.unity, from: keyResult.start_value % 1 != 0 ? $options.filters.numeral(keyResult.start_value ,  '0,0.00') : $options.filters.numeral(keyResult.start_value ,  '0,0')}) }}</div>
                                        </template>
                                    </v-popover>
                                </div>
    
                                <!-- last actualization -->
                                <div class="column-info">
                                    <span class="label">{{ $t('general.last_update') }}:</span>
    
                                    <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                                        <div class="value" v-if="keyResult.needs_an_update == 'today' || keyResult.needs_an_update == 'past'" @click="($auth.check() && keyResult.rights.can_create_log) ? openModal('add_kr_update', keyResult) : ''" :class="{is_link: keyResult.rights.can_create_log}">
                                            <icon-circle-check class="orange"/>
                                            <span class="text" v-if="$auth.check() && keyResult.rights.can_create_log">{{ $t('key_results.add_update') }}</span>
                                            <span class="text" v-else>{{ $t('meeting_session.no_updates') }}</span>
                                        </div>
                                        <div class="value" v-else-if="keyResult.last_key_result_log" @click="($auth.check() && keyResult.rights.can_create_log) ? openModal('kr_updates', keyResult) : ''" :class="{is_link: keyResult.rights.can_create_log}">
                                            <icon-circle-check class="green"/>
                                            <span class="text">{{ $tc('key_results.days-ago', diffDaysFrom(keyResult.last_key_result_log.created_at)) }}</span>
                                        </div>
                                        <div class="value" v-else @click="($auth.check() && keyResult.rights.can_create_log) ? openModal('add_kr_update', keyResult) : ''" :class="{is_link: keyResult.rights.can_create_log}">
                                            <span class="text" v-if="$auth.check() && keyResult.rights.can_create_log">{{ $t('key_results.add_update') }}</span>
                                            <span class="text" v-else>{{ $t('meeting_session.no_updates') }}</span>
                                        </div>
        
                                        <template slot="popover">
                                            <div class="simple-text" v-if="keyResult.needs_an_update == 'today'">{{ $t('key_results.tooltip-update-today') }}</div>
                                            <div class="simple-text" v-else-if="keyResult.needs_an_update == 'past'">{{ $t('key_results.tooltip-update-past') }}</div>
                                            <div class="simple-text" v-else-if="keyResult.needs_an_update">{{ $tc('key_results.tooltip-update-future', keyResult.needs_an_update) }}</div>
                                            <div class="simple-text" v-else-if="keyResult.status == 'upcoming'">{{ $t('key_results.tooltip-update-cant') }}</div>
                                            <div class="simple-text" v-else>{{ $tc('key_results.tooltip-updates-view') }}</div>
                                        </template>
                                    </v-popover>
                                </div>
    
                                <!-- progress -->
                                <div class="column-info">
                                    <span class="label">{{ $t('general.progress')}}:</span>
                                    <span class="value">{{ keyResult.percent}}%</span>
                                </div>
    
                                <!-- deadline -->
                                <div class="column-info">
                                    <span class="label">{{ $t('show_user.deadline')}}:</span>
                                    <span class="value">{{ moment(keyResult.deadline).format('DD MMM') }}</span>
                                </div>
                                
                                <!-- avatar -->
                                <div class="column-avatar">
                                    <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="keyResult.responsible.avatar">
                                        <img :src="keyResult.responsible.avatar" >
                                        <template slot="popover">
                                            <div class="simple-text">{{ keyResult.responsible.name }}</div>
                                        </template>
                                    </v-popover>
                                    <div class="user-circle" v-else>
                                        <icon-user />
                                    </div>
                                </div>
                            </div>

                            <template v-for="(task, index) in keyResult.tasks">
                                <RowTask :task="task" :keyResultId="keyResult.id" :index="index" :extraInfo="['update', 'key_result', 'date']" fromStep="key_result" v-if="$auth.check() && ($parent.can_lead || (keyResult.responsible ? keyResult.responsible.id == $auth.user().id : false))"/>
                                <ReadTask :task="task" :extraInfo="['date', 'key_result']" fromStep="key_result"  v-else/>
                            </template>

                            <div class="row-item add-item" v-if="$auth.check() && ($parent.can_lead || (keyResult.responsible ? keyResult.responsible.id == $auth.user().id : false))">
                                <div class="column-icon">
                                    <icon-plus />
                                </div>
                                <div class="column-input">
                                    <input type="text" :placeholder="$t('dashboard.placeholder_add_task')" v-model="new_task[`kr_${keyResult.id}`].name" @keyup.enter="storeTask(keyResult)"/>
                                </div>
                                <div class="column-input-date">
                                    <div class="deadline-calendar top">
                                        <InputCalendarPicker :data="new_task[`kr_${keyResult.id}`].deadline" @updateDate="(deadline) => { new_task[`kr_${keyResult.id}`].deadline = deadline.value }"/>
                                    </div>
                                </div>

                                <div class="column-button">
                                    <button class="btn-tbf blue center" @click="storeTask(keyResult)">
                                        <div class="text">{{ $t('general.add') }}</div>
                                    </button>
                                </div>

                                <div class="row-info-action" v-if="new_task[`kr_${keyResult.id}`].name">
                                    <div class="text">{{ $t('meeting_session.remainder_add_task') }}</div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <slot name="form_submit"></slot>
                </div>
            </div>
        </template>
        <loaderObjective v-else/>
    </div>
</template>

<script>
import IconTask from '@/components/Icons/Task'
import IconCheck from '@/components/Icons/Check'
import IconCircleCheck from '@/components/Icons/CircleCheck'
import IconPlus from '@/components/Icons/Plus'
import IconFlag from '@/components/Icons/Flag'
import IconInfo from '@/components/Icons/CircleInfo'

import LoaderObjective from '@/components/PagesLoaders/MeetingObjective'
import RowTask from '@/components/ComponentsTask/RowTask'
import InputCalendarPicker from '@/components/ComponentsTask/CalendarPicker'
import InputUserSelect from '@/components/ComponentsTask/UserSelect'
import ReadTask from '@/components/ComponentsTask/ReadTask'

export default {
    components: {
        IconTask,
        IconCheck,
        IconFlag,
        IconCircleCheck,
        IconPlus,
        LoaderObjective,
        RowTask,
        InputCalendarPicker,
        InputUserSelect,
        ReadTask,
        IconInfo
    },
    props: {
        optionsUsers: Array,
        data: Object|Boolean
    },
    watch: {
        data(newValue) {
            this.loaded = false;
            this.getObjectiveData();
        }
    },
    data() {
        return {
            loaded: false,
            loadedFinish: false,
            typeSubject: 'evaluation',
            guests: [],
            objective: {},
            new_task: {},
            loadingNewTask: {}
        }
    },
    beforeDestroy() {
        this.$root.$off("updateTaskKeyResultMeeting");
        this.$root.$off("updateKeyResultMeeting");
    },
    async mounted() {
        await this.getObjectiveData();

        this.$root.$on('updateTaskKeyResultMeeting', (taskData) => {
            this.addTaskFromWebsocket(taskData);
        });

        this.$root.$on('updateKeyResultMeeting', (keyResultData) => {
            this.updateKeyResultFromWebsocket(keyResultData);
        });
    },
    methods: {
        async getObjectiveData(){
            await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/public/${this.$parent.masterInstanceId}/objectives/${this.data.slug}/objective-info`)
            .then(({data}) => {
                this.objective = data.data;

                data.data.key_results.map(el => {
                    this.new_task[`kr_${el.id}`] = {name: '',deadline: '',responsible: ''}
                })
            }).finally(() => {
                this.loaded = true;
            })
        },
        changeStepFunction(direction, stepNo = false) {
            if(direction) { 
                this.$emit(direction);
            } else if(stepNo) {
                this.$emit('goToStep', stepNo)
            }
        },
        changeStepOrRedirect(changeStep, withRedirect, userSlug) {
            if(changeStep) {
                this.$nextTick(() => {
                    if(!changeStep.step) {
                        this.$emit(changeStep.direction);
                    } else {
                        this.$emit('goToStep', changeStep.step)
                    }
                });
            }

            if(withRedirect) {
                if(this.$route.query.department_id){
                    this.$router.push({name: 'company'})
                }else{
                    this.$router.push({name: 'user-show', params: { slug: userSlug }})
                }
            }
        },
        addTaskFromWebsocket(taskData) {
            switch (taskData.action) {
                case 'store':
                    var keyResult = this.objective.key_results.find(el => el.id == taskData.key_result_id);
                    if( keyResult ) {
                        keyResult.tasks.push(taskData);
                    }
                    break;
                case 'update':
                    var keyResult = this.objective.key_results.find(el => el.id == taskData.key_result_id);
                    if( keyResult ) {
                        // find task and update it
                        var task = keyResult.tasks.find(el => el.id == taskData.task_id);
                        if( task ) {
                            task.name = taskData.name;
                            task.deadline = taskData.deadline;
                            task.done = taskData.done;
                        }
                    }
                    break;
            }
        },
        diffDaysFrom(update_date){
            var a = moment(update_date).startOf('day');
            var b = moment().startOf('day');
            return b.diff(a, 'days');  
        },
        returnValue(value){
            const roundedNumber = Math.round(value * 100) / 100; // Round to 2 decimal places

            if (Number.isInteger(roundedNumber)) {
            return roundedNumber.toFixed(0); // Remove decimal places for integers
            } else {
            return roundedNumber.toFixed(2); // Preserve 2 decimal places for non-integers
            }
        },
        storeTask(keyResult) {
            if(this.new_task[`kr_${keyResult.id}`].name != '' && !this.loadingNewTask[`kr_${keyResult.id}`]) {
                this.loadingNewTask[`kr_${keyResult.id}`] = true;

                var formData = {
                    name: this.new_task[`kr_${keyResult.id}`].name,
                    key_result_id: keyResult.id,
                    user_id: keyResult.responsible.id,
                    from_meeting_id: this.$parent.meetingLog.id,
                    from_meeting_step: 'key_result'
                };

                if(this.new_task[`kr_${keyResult.id}`].deadline != '') {
                    formData.deadline = this.new_task[`kr_${keyResult.id}`].deadline;
                } else {
                    formData.deadline = moment().format('YYYY-MM-DD');
                }

                axios.post(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/${keyResult.id}/tasks/store`, formData)
                .then(({data}) => {
                    const newTask = {...formData, id: data.data.id, responsible: this.optionsUsers.find(el => el.id == keyResult.responsible.id), done: 0};
                    keyResult.tasks.push(newTask);
                    this.new_task[`kr_${keyResult.id}`] = { name: '', deadline: '', responsible: '' }

                    this.$parent.socket.send(JSON.stringify({
                        'entity_id': this.$parent.meetingLog.id,
                        'entity_user_id': this.$auth.user().id,
                        'type': 'meeting',
                        'custom_data': {
                            'type': 'kr_task',
                            'data': {
                                ...newTask,
                                'action': 'store'
                            }
                        }
                    }));

                    this.loadingNewTask[`kr_${keyResult.id}`] = false;
                })
            }
        },
        openModal(type, keyResult){
            this.$root.$emit('open_modal_full_template', type, {
                keyResult: keyResult,
                meetingId: this.$parent.meetingLog.id
            })
        },
        updateKeyResultFromWebsocket(keyResultData) {
            var keyResultIndex = this.objective.key_results.findIndex(el => el.id == keyResultData.key_result_id);

            if(keyResultIndex != -1) {
                this.objective.key_results[keyResultIndex].last_key_result_log = keyResultData.last_key_result_log;
                this.objective.key_results[keyResultIndex].percent = keyResultData.percent;
                this.objective.key_results[keyResultIndex].needs_an_update = keyResultData.needs_an_update;
                this.objective.percent = keyResultData.objective_percent;
            }
        },
    }
}
</script>
